<template>
  <div class="vx-row">
    <summary-card
      v-if="summary.display_search"
      classes="vx-col md:w-1/3 sm:w-1/2 w-full mb-6"
      title="Display Search"
      :data="summary.display_search"
    />

    <summary-card
      v-if="summary.crm"
      classes="vx-col md:w-1/3 sm:w-1/2 w-full mb-6"
      title="CRM"
      :data="summary.crm"
    />

    <summary-card
      v-if="summary.social_media"
      classes="vx-col md:w-1/3 sm:w-1/2 w-full mb-6"
      title="Social Media"
      :data="summary.social_media"
    />

    <summary-card
      v-if="summary.reviews"
      classes="vx-col md:w-1/3 sm:w-1/2 w-full mb-6"
      title="Reviews"
      :data="summary.reviews"
    />

    <summary-card-offline
      v-if="summary.offline"
      classes="vx-col md:w-1/3 sm:w-1/2 w-full mb-6"
      title="Offline"
      :data="summary.offline"
      :spent="summary.offline.spent"
      :units-budget="summary.offline.units_budget"
      :units-sold="summary.offline.units_sold"
      :budget-revenue="summary.offline.budget_revenue"
      :revenue="summary.offline.revenue"
      :user-id="userId"
      @save="saveOffline"
    />

    <summary-card
      v-if="summary.total"
      classes="vx-col md:w-full sm:w-1/2 w-full mb-6"
      title="Total"
      title-color="white"
      card-background="primary"
      content-color="white"
      label-class="text-white"
      :data="summary.total"
      total
    />

    <div v-if="hasNoData" class="mt-10 w-full text-center">
      No data Available
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';

import manageCampaignMixin from '@/mixins/manageCampaignMixin';

import SummaryCard from '@/views/ui-elements/campaign/SummaryCard';
import SummaryCardOffline from '@/views/ui-elements/campaign/SummaryCardOffline';

export default {
  components: {
    SummaryCard,
    SummaryCardOffline,
  },
  mixins: [manageCampaignMixin],
  props: {
    userId: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    ...mapState('campaign', { summary: ({ summary }) => summary }),

    campaignId() {
      return +this.$route.params.id;
    },
    hasNoData() {
      return (
        !this.summary.display_search &&
        !this.summary.reviews &&
        !this.summary.offline &&
        !this.summary.total
      );
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.fetchSummary,
      CampaignAction.updateSummaryOfflineActivity,
    ]),

    async saveOffline(item) {
      if (!this.ableToEditCampaign(this.userId)) {
        return;
      }

      try {
        const newSummary = Object.assign(
          {},
          { campaignId: this.campaignId },
          item
        );

        await this.updateSummaryOfflineActivity(newSummary);

        this.notifySuccess(
          'Summary Offline updated',
          'The summary of offline activities has been successfully updated.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with updating a summary.');
      }
    },
  },
  async created() {
    this.$vs.loading();

    await this.fetchSummary(this.campaignId);

    this.$vs.loading.close();
  },
};
</script>
