<template>
  <div>
    <div
      v-for="product in products"
      :key="`product-${product.id}`"
      class="group-row"
    >
      <vx-card class="mt-4 no-body-padding campaign-is-group">
        <div class="flex justify-start">
          <div class="column column-image" :style="{ width: '120px' }">
            <img :src="product.pictureUrl" class="img-picture" />
          </div>
          <div class="column" :style="{ width: '36%' }">
            {{ product.name }}
          </div>
          <div class="column" :style="{ width: '20%' }">
            {{ product.brand }}
          </div>
          <div class="column" :style="{ width: '20%' }">
            {{ product.category }}
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      default: () => {},
      require: true,
    },
    products: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.img-picture {
  max-width: 60px;
  height: 60px;
}

.group-row {
  padding-left: 50px;
}

.column {
  padding: 2rem 1rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.column-image {
  justify-content: center;
  padding-left: 0;
}
</style>
