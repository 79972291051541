import MathUtil from '@/utils/MathUtil';

export default {
  data: () => ({
    c: {
      _currency: '',
      _sign: '',
      _rate: 1,
    },
  }),
  computed: {
    currency() {
      return (baseValue) => {
        const value = MathUtil.round(baseValue * this.c._rate);

        return `${value}${this.c._sign}`;
      };
    },
  },
  created() {
    this.c._currency = this.$store.state.currency;
    this.c._sign = this.$store.state.currencySign;
    this.c._rate = this.$store.state.currencyRate;
  },
  watch: {
    '$store.state.currency'(val) {
      this.$set(this.c, '_currency', val);
    },
    '$store.state.currencySign'(val) {
      this.$set(this.c, '_sign', val);
    },
    '$store.state.currencyRate'(val) {
      this.$set(this.c, '_rate', val);
    },
  },
};
