<template>
  <vs-button
    type="line"
    class="font-medium"
    :color="color"
    @click="$emit('click')"
  >
    {{ title }}
  </vs-button>
</template>

<script>
export default {
  name: 'TabBtn',
  props: {
    active: {
      type: Boolean,
      require: true,
    },
    title: {
      type: String,
      default: '',
    },
    activeColor: {
      type: String,
      default: 'primary',
    },
    notActiveColor: {
      type: String,
      default: 'dark',
    },
  },
  computed: {
    color() {
      return this.active ? this.activeColor : this.notActiveColor;
    },
  },
};
</script>
