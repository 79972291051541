import axios from '@/axios';
import { ProductAction } from '@/store/actionTypes';
import { ProductMutation } from '@/store/mutationTypes';

const handleUploadPhoto = (commit, resolve, reject, id, picture) => {
  return axios
    .post(`/products/${id}/picture`, picture, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      commit(ProductMutation.UPDATE, response.data);
      resolve(response);
    })
    .catch(reject);
};

export default {
  [ProductAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/products', item)
        .then((response) => {
          commit(ProductMutation.ADD, response.data);
          resolve(response);

          new Promise(() => {
            return handleUploadPhoto(
              commit,
              resolve,
              reject,
              response.data.id,
              item.pictureFile
            );
          });
        })
        .catch(reject);
    });
  },
  [ProductAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/products')
        .then((response) => {
          commit(ProductMutation.SET, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [ProductAction.update]({ commit }, item) {
    return Promise.all([
      new Promise((resolve, reject) => {
        return axios
          .put(`/products/${item.id}`, item)
          .then((response) => {
            commit(ProductMutation.UPDATE, response.data);
            resolve(response);
          })
          .catch(reject);
      }),
      new Promise((resolve, reject) => {
        return handleUploadPhoto(
          commit,
          resolve,
          reject,
          item.id,
          item.pictureFile
        );
      }),
    ]);
  },
  [ProductAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/products/${id}`)
        .then((response) => {
          commit(ProductMutation.REMOVE, id);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [ProductAction.removePicture]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/products/${id}/picture`)
        .then((response) => {
          commit(ProductMutation.UPDATE, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
};
