<template>
  <vs-sidebar
    v-model="isActive"
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PRODUCT</h4>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click.stop="isActive = false"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <div class="tabs-container">
        <vs-tabs class="tab-action-btn-fill-conatiner custom-tab-switcher">
          <!-- SINGLE PRODUCT -->
          <vs-tab
            label="Single product"
            icon-pack="feather"
            icon="icon-package"
          >
            <div>
              <div>
                <vs-label text="Single product" />
                <v-select
                  v-model="dataProduct"
                  :options="productChoices"
                  :clearable="false"
                />
              </div>

              <sidebar-buttons
                :valid="isFormValid"
                @click="handleSubmit"
                @cancel="handleClose"
              />
            </div>
          </vs-tab>

          <!-- PRODUCTS BY GROUP -->
          <vs-tab label="Product group" icon-pack="feather" icon="icon-layers">
            <div>
              <div>
                <vs-label text="Product group" />
                <v-select
                  v-model="productGroup"
                  :options="productGroups"
                  :clearable="false"
                  :get-option-label="
                    (option) => {
                      return option.name;
                    }
                  "
                />
              </div>

              <sidebar-buttons
                :valid="isFormValidGroup"
                @click="handleGroupSubmit"
                @cancel="handleClose"
              />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';

import {
  CampaignAction,
  ProductAction,
  ProductGroupAction,
} from '@/store/actionTypes';

import moduleProduct from '@/store/products/moduleProduct';
import moduleProductGroup from '@/store/product-groups/moduleProductGroup';
import sidebarMixin from '@/mixins/sidebarMixin';

export default {
  components: {
    vSelect,
  },
  mixins: [sidebarMixin],
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dataProduct: null,
      productGroup: null,
    };
  },
  watch: {
    active(val) {
      if (!val) {
        return;
      }

      this.initValues();
      this.$validator.reset();
    },
  },
  computed: {
    ...mapState('product', {
      products: ({ products }) => products,
    }),
    ...mapState('product-group', {
      productGroups: ({ productGroups }) => productGroups,
    }),

    isFormValid() {
      return !this.errors.any() && this.dataProduct;
    },
    isFormValidGroup() {
      return !this.errors.any() && this.productGroup;
    },
    productChoices() {
      const choices = this.products.map(
        ({ id, name, brandName, categoryName }) => {
          const details = categoryName
            ? `[${categoryName} - ${brandName}]`
            : `[${brandName}]`;

          return { id, label: `${name} ${details}` };
        }
      );

      return choices;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.addProduct,
      CampaignAction.addProductGroup,
    ]),
    ...mapActions('product', [ProductAction.fetch]),
    ...mapActions('product-group', [
      ProductGroupAction.fetch,
      ProductGroupAction.fetchByGroup,
    ]),

    registerStoreModules() {
      if (!moduleProduct.isRegistered) {
        this.$store.registerModule('product', moduleProduct);
        moduleProduct.isRegistered = true;
      }
      if (!moduleProductGroup.isRegistered) {
        this.$store.registerModule('product-group', moduleProductGroup);
        moduleProductGroup.isRegistered = true;
      }
    },
    initValues() {
      this.dataProduct = null;
      this.productGroup = null;
    },
    async handleSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.$vs.loading();

      try {
        await this.addProduct({
          campaignId: this.campaignId,
          productId: this.dataProduct.id,
        });

        this.$emit('submitted');

        this.notifySuccess(
          'Campaign product added',
          'The campaign product has been successfully added.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with adding a product.');
      }

      this.$vs.loading.close();
      this.$emit('close');
      this.initValues();
    },
    async handleGroupSubmit() {
      if (!(await this.$validator.validateAll())) {
        return;
      }

      this.$vs.loading();

      try {
        await this.addProductGroup({
          campaignId: this.campaignId,
          productGroupId: this.productGroup.id,
        });

        this.$emit('submitted');

        this.notifySuccess(
          'Product group added',
          'The campaign products has been successfully added.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with adding a product.');
      }

      this.$vs.loading.close();
      this.$emit('close');
      this.initValues();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await Promise.all([this.fetchProductGroups(), this.fetchProducts()]);
    } catch (error) {
      this.notifyError(error, 'Problem with loading products.');
    }

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss">
.custom-tab-switcher {
  & > .con-ul-tabs > ul {
    justify-content: space-around !important;
  }
}
</style>
