<template>
  <div class="group-buttons cursor-pointer" @click.stop="$emit('click')">
    <div class="group-buttons__wrapper">
      <feather-icon
        icon="LayersIcon"
        svg-classes="w-5 h-5 text-white stroke-current"
      />
      <feather-icon
        class="icon-arrow"
        :icon="arrowIcon"
        svg-classes="w-5 h-5 text-white stroke-current"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    arrowIcon() {
      return this.isOpen ? 'ArrowDownIcon' : 'ArrowUpIcon';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/app/variables.scss';

.group-buttons {
  position: relative;
  height: 100%;
  padding-left: 13px;
  width: 43px;
  overflow: hidden;
  transition: width 0.1s ease-in-out;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  &:hover {
    width: 70px;
  }

  .icon-arrow {
    margin-left: 10px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 13px;
    width: 70px;
    height: 100%;
    background-color: $campaign-group-toggle-bg-color;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}
</style>
