<template>
  <data-list-view-wrapper>
    <h3 v-if="campaignName" class="mb-1">Campaign: {{ campaignName }}</h3>

    <campaign-product-sidebar
      v-if="ableToEditCampaign(campaignUserId)"
      :active="addNewProductSidebar"
      :campaign-id="campaignId"
      @close="toggleProductDataSidebar"
      @submitted="refetchProductOrGroup"
    />

    <offline-activity-sidebar
      :active="addNewOfflineActivitySidebar"
      :data="offlineActivitySidebarData"
      @close="toggleOfflineActivityDataSidebar"
    />

    <div class="flex column items-center md:justify-center justify-start mb-6">
      <div class="btn-group">
        <tab-btn
          title="Products list"
          :active="isActiveTab(Tabs.Products)"
          @click="setActiveTab(Tabs.Products)"
        />
        <!-- <tab-btn
          title="Status review"
          :active="isActiveTab(Tabs.StatusReview)"
          @click="setActiveTab(Tabs.StatusReview)"
        /> -->
        <tab-btn
          title="Offline activities"
          :active="isActiveTab(Tabs.Offline)"
          @click="setActiveTab(Tabs.Offline)"
        />
        <tab-btn
          title="Summary"
          :active="isActiveTab(Tabs.Summary)"
          @click="setActiveTab(Tabs.Summary)"
        />
        <tab-btn
          title="Attachments"
          :active="isActiveTab(Tabs.Attachments)"
          @click="setActiveTab(Tabs.Attachments)"
        />
      </div>
    </div>

    <!-- PRODUCTS -->
    <div v-show="isActiveTab(Tabs.Products)">
      <div
        v-if="ableToEditCampaign(campaignUserId)"
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create title="Add product or group" @click="addNewProduct" />
        </div>
      </div>
      <h4 class="ml-5 mt-4">Products and groups</h4>

      <div class="flex justify-between mt-4 custom-table-header">
        <div :style="{ width: '120px', paddingLeft: '1.25rem' }">Image</div>
        <div :style="{ width: '36%' }">Name</div>
        <div :style="{ width: '20%' }">Brand</div>
        <div :style="{ width: '20%' }">Category</div>
        <div :style="{ width: '10%' }">Action</div>
      </div>

      <div>
        <div v-for="item in productsAndGroups" :key="`pg-${item.id}`">
          <vx-card
            class="mt-6 no-body-padding"
            :class="{ 'campaign-is-group': isGroup(item) }"
          >
            <div class="flex justify-between">
              <div class="img-container" :style="{ width: '120px' }">
                <group-toggle
                  v-show="isGroup(item)"
                  :is-open="isGroupOpened(item.id)"
                  @click="toggleGroupProducts(item.id)"
                />
                <img
                  v-show="!isGroup(item)"
                  :src="item.pictureUrl"
                  class="product-img"
                />
              </div>
              <div
                class="column-padding cursor-pointer"
                :style="{ width: '36%' }"
                @click="configureProduct(item)"
              >
                {{ item.name }}
              </div>
              <div
                class="column-padding cursor-pointer"
                :style="{ width: '20%' }"
                @click="configureProduct(item)"
              >
                {{ item.brandName }}
              </div>
              <div
                class="column-padding cursor-pointer"
                :style="{ width: '20%' }"
                @click="configureProduct(item)"
              >
                {{ item.categoryName }}
              </div>
              <div class="column-padding" :style="{ width: '10%' }">
                <feather-icon
                  v-if="ableToEditCampaign(campaignUserId) && isGroup(item)"
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                  class="ml-2"
                  @click.stop="
                    confirmDelete(item.id, item.name, 'deleteProductGroup')
                  "
                />
                <feather-icon
                  v-if="ableToEditCampaign(campaignUserId) && !isGroup(item)"
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                  class="ml-2"
                  @click.stop="
                    confirmDelete(item.id, item.name, 'deleteProduct')
                  "
                />
              </div>
            </div>
          </vx-card>

          <groupped-products
            v-if="isGroup(item) && currentOpenGroupId === item.id"
            :group="item"
            :products="item.productList"
          />
        </div>
      </div>
    </div>
    <!-- / PRODUCTS -->

    <!-- STATUS REVIEW -->
    <!-- <status-review-tab v-show="isActiveTab(Tabs.StatusReview)" /> -->
    <!-- / STATUS REVIEW -->

    <!-- OFFLINE ACTIVITIES -->
    <vx-table
      v-show="isActiveTab(Tabs.Offline)"
      ref="tableOfflineActivities"
      search
      :data="offlineActivities"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <button-create
            v-if="ableToEditCampaign(campaignUserId)"
            title="Add offline activity"
            @click="addNewOfflineActivity"
          />
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="activity">Activity</vs-th>
        <vs-th sort-key="budget">Budget</vs-th>
        <vs-th sort-key="start_date">Start date</vs-th>
        <vs-th sort-key="end_date">End date</vs-th>
        <vs-th sort-key="reach">Reach</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
            <vs-td>
              <p class="product-activity">
                {{ tr.activity }}
              </p>
            </vs-td>

            <vs-td>
              <p class="product-budget">{{ currency(tr.budget) }}</p>
            </vs-td>

            <vs-td>
              <p class="product-start_date">{{ tr.start_date | title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-end_date">{{ tr.end_date | title }}</p>
            </vs-td>

            <vs-td>
              <p class="product-reach">{{ tr.reach | title }}</p>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                v-if="ableToEditCampaign(campaignUserId)"
                icon="SettingsIcon"
                svg-classes="w-5 h-5 hover:text-primary stroke-current"
                @click.stop="editOfflineActivityData(tr)"
              />
              <feather-icon
                v-if="ableToEditCampaign(campaignUserId)"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="
                  confirmDelete(tr.id, tr.activity, 'deleteOfflineActivity')
                "
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
    <!-- / OFFLINE ACTIVITIES -->

    <!-- SUMMARY -->
    <summary-tab v-if="isActiveTab(Tabs.Summary)" :user-id="campaignUserId" />
    <!-- / SUMMARY -->

    <!-- ATTACHMENTS -->
    <vx-table
      v-show="isActiveTab(Tabs.Attachments)"
      ref="tableAttachments"
      :data="files"
    >
      <div slot="header" class="flex flex-col upload-wrapper">
        <file-pond
          v-if="ableToEditCampaign(campaignUserId)"
          ref="campaignFilesPond"
          name="files"
          label-idle="Drop files here or <span class='filepond--label-action'>Browse</span>"
          allow-multiple="true"
          credits="false"
          :files="campaignFilesUpload"
        />
        <vs-button @click="handleFilesUpload">Upload</vs-button>
      </div>

      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="created_at">Uploaded at</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" :data="tr">
            <vs-td>
              <p class="name">
                {{ tr.name }}
              </p>
            </vs-td>

            <vs-td>
              <p class="created_at">
                {{ tr.created_at | formatDate }}
              </p>
            </vs-td>

            <vs-td width="120" class="whitespace-no-wrap">
              <feather-icon
                icon="DownloadIcon"
                svg-classes="w-5 h-5 hover:text-success stroke-current"
                @click.stop="handleFileDownload(tr.id, tr.name)"
              />
              <feather-icon
                v-if="ableToEditCampaign(campaignUserId)"
                icon="TrashIcon"
                svg-classes="w-5 h-5 hover:text-danger stroke-current"
                class="ml-2"
                @click.stop="confirmDelete(tr.id, tr.name, 'deleteFile')"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vx-table>
    <!-- / FILES -->
  </data-list-view-wrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import downloadjs from 'downloadjs';
import VueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';

import { CampaignAction } from '@/store/actionTypes';

import confirmationMixin from '@/mixins/confirmationMixin';
import dataListMixin from '@/mixins/dataListMixin';
import manageCampaignMixin from '@/mixins/manageCampaignMixin';
import moduleCampaign from '@/store/campaigns/moduleCampaign';
import tabsMixin from '@/mixins/tabsMixin';
import useCurrencyMixin from '@/mixins/useCurrencyMixin';

import ButtonCreate from '@/views/components/buttons/ButtonCreate';
import CampaignProductSidebar from '@/views/sidebars/CampaignProductSidebar';
import DataListViewWrapper from '@/views/wrappers/DataListViewWrapper';
import GrouppedProducts from '@/views/ui-elements/campaign/GrouppedProducts';
import GroupToggle from '@/views/ui-elements/campaign/GroupToggle';
import OfflineActivitySidebar from '@/views/sidebars/OfflineActivitySidebar';
// import StatusReviewTab from '@/views/ui-elements/campaign/StatusReviewTab';
import SummaryTab from '@/views/ui-elements/campaign/SummaryTab';
import TabBtn from '@/views/components/tabs/TabButton';
import VxTable from '@/components/vx-table/vxTable';

const FilePond = new VueFilePond();

export default {
  components: {
    ButtonCreate,
    CampaignProductSidebar,
    DataListViewWrapper,
    FilePond,
    GrouppedProducts,
    GroupToggle,
    OfflineActivitySidebar,
    // StatusReviewTab,
    SummaryTab,
    TabBtn,
    VxTable,
  },
  mixins: [
    confirmationMixin,
    dataListMixin,
    manageCampaignMixin,
    tabsMixin,
    useCurrencyMixin,
  ],
  data() {
    return {
      campaignName: null,
      campaignUserId: null,
      addNewProductSidebar: false,
      addNewOfflineActivitySidebar: false,
      offlineActivitySidebarData: {},
      campaignFilesUpload: [],
      files: [],
      productsAndGroups: [],
      currentOpenGroupId: null,
    };
  },
  computed: {
    ...mapState('campaign', {
      products: ({ products }) => products,
      productGroups: ({ productGroups }) => productGroups,
      offlineActivities: ({ offlineActivities }) => offlineActivities,
    }),

    campaignId() {
      return +this.$route.params.id;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.fetchOne,
      CampaignAction.fetchProducts,
      CampaignAction.fetchProductGroups,
      CampaignAction.fetchFiles,
      CampaignAction.fetchStatusReviews,
      CampaignAction.fetchOfflineActivities,
      CampaignAction.fetchSummary,
      CampaignAction.downloadFile,
      CampaignAction.uploadFiles,
      CampaignAction.removeProduct,
      CampaignAction.removeProductGroup,
      CampaignAction.removeOfflineActivity,
      CampaignAction.removeFile,
    ]),

    registerStoreModules() {
      if (!moduleCampaign.isRegistered) {
        this.$store.registerModule('campaign', moduleCampaign);
        moduleCampaign.isRegistered = true;
      }
    },
    addNewProduct() {
      if (!this.ableToEditCampaign(this.campaignUserId)) {
        return;
      }

      this.toggleProductDataSidebar(true);
    },
    addNewOfflineActivity() {
      this.offlineActivitySidebarData = {};
      this.toggleOfflineActivityDataSidebar(true);
    },
    editOfflineActivityData(data) {
      this.offlineActivitySidebarData = data;
      this.toggleOfflineActivityDataSidebar(true);
    },
    async handleFilesUpload() {
      if (!this.$refs.campaignFilesPond.getFiles().length) {
        return;
      }

      const filesForm = new FormData();
      this.$refs.campaignFilesPond.getFiles().forEach((file) => {
        filesForm.append('files[]', file.file);
      });

      try {
        this.files = await this.uploadFiles({
          campaignId: this.campaignId,
          files: filesForm,
        });

        this.$refs.campaignFilesPond.removeFiles();

        this.notifySuccess('Uploaded', 'Files uploaded successfully.');
      } catch (error) {
        if (error.response.status === 413) {
          this.notifyError('Error', 'At least one file is too large.');
          return;
        }

        this.notifyError('Error', 'Problem with uploading some files.');
      }
    },
    async deleteFile(id) {
      this.files = await this.removeFile({
        campaignId: this.campaignId,
        fileId: id,
      });
    },
    async handleFileDownload(fileId, name) {
      const res = await this.downloadFile({
        campaignId: this.campaignId,
        fileId,
      });

      downloadjs(res.data, name, res.headers['content-type']);
    },
    async deleteProduct(id) {
      if (!this.ableToEditCampaign(this.campaignUserId)) {
        return;
      }

      try {
        await this.removeProduct(id);

        this.refetchProductOrGroup();

        this.notifySuccess(
          'Campaign product deleted',
          'The campaign product has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a product.');
      }
    },
    async deleteProductGroup(id) {
      if (!this.ableToEditCampaign(this.campaignUserId)) {
        return;
      }

      try {
        await this.removeProductGroup(id);

        this.refetchProductOrGroup();

        this.notifySuccess(
          'Campaign product group deleted',
          'The campaign product group has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a product group.');
      }
    },
    async deleteOfflineActivity(id) {
      try {
        await this.removeOfflineActivity(id);

        this.notifySuccess(
          'Offline Activity deleted',
          'The offline activity has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting an offline activity.');
      }
    },
    isGroupOpened(groupId) {
      return this.currentOpenGroupId === groupId;
    },
    isGroup(item) {
      return 'productList' in item;
    },
    configureProduct(obj) {
      if (this.isGroup(obj)) {
        this.$router
          .push({
            name: 'campaign-product-group-configuration',
            params: { id: this.campaignId, productGroupId: obj.id },
          })
          .catch();
        return;
      }

      this.$router
        .push({
          name: 'campaign-product-configuration',
          params: { id: this.campaignId, productId: obj.id },
        })
        .catch();
    },
    toggleProductDataSidebar(val = false) {
      this.addNewProductSidebar = val;
    },
    toggleOfflineActivityDataSidebar(val = false) {
      this.addNewOfflineActivitySidebar = val;
    },
    toggleGroupProducts(groupId) {
      if (this.currentOpenGroupId === groupId) {
        this.currentOpenGroupId = null;
        return;
      }

      this.currentOpenGroupId = groupId;
    },
    async refetchProductOrGroup() {
      this.$vs.loading();

      const [groups, products] = await Promise.all([
        this.fetchProductGroups(this.campaignId),
        this.fetchProducts(this.campaignId),
      ]);

      this.productsAndGroups = [...groups, ...products];

      this.$vs.loading.close();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      const [campaign, groups, products] = await Promise.all([
        this.fetchCampaign(this.campaignId),
        this.fetchProductGroups(this.campaignId),
        this.fetchProducts(this.campaignId),
        this.fetchStatusReviews(this.campaignId),
        this.fetchOfflineActivities(this.campaignId),
        this.fetchSummary(this.campaignId),
        this.fetchFiles(this.campaignId),
      ]);

      const { name, user_id, files } = campaign.data;

      this.productsAndGroups = [...groups, ...products];
      this.campaignName = name;
      this.campaignUserId = user_id;
      this.files = files;
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss" scoped>
.upload-wrapper,
.filepond--wrapper {
  width: 100%;
}

.product-img {
  max-width: 100%;
}

.upload-wrapper {
  align-items: flex-start;
}

.column-padding {
  padding: 3rem 1.5rem 3rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-table-header {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.85rem;
}
</style>
