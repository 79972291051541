export const Tabs = {
  Attachments: 'attachments',
  Offline: 'offline',
  Planning: 'planning',
  Products: 'products',
  StatusReview: 'statusReview',
  Summary: 'summary',
};

export default {
  data() {
    return {
      activeTab: Tabs.Products,
      Tabs,
    };
  },
  methods: {
    isActiveTab(tab) {
      return this.activeTab === tab;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  },
};
