import { UserRoleEnum } from '@/enums/UserRoleEnum';

export default {
  computed: {
    ableToEditCampaign() {
      const { id, role } = this.$store.state.AppActiveUser;
      const roleAbleToEdit =
        role === UserRoleEnum.SYSADMIN.value ||
        role === UserRoleEnum.GLOBAL.value;

      return (userId) => id === userId || roleAbleToEdit;
    },
    ableToDeleteCampaign() {
      const { id, role } = this.$store.state.AppActiveUser;
      const roleAbleToEdit =
        role === UserRoleEnum.SYSADMIN.value ||
        role === UserRoleEnum.GLOBAL.value;

      return (userId) => id === userId || roleAbleToEdit;
    },
  },
};
